import React from 'react'
import SEO from '../components/App/SEO'
import Layout from '../components/App/Layout'
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import CustomersComp from "../components/Customers/Customers"
import { graphql } from 'gatsby'

const Customers = ({data}) => {
    return (
        <Layout env={data.site.siteMetadata.env}>
            <Navbar />
            <SEO 
                postTitle="BlueMail Customers"
                postDescription="BlueMail Customers List"
                postImage="/img/OG/og_image_Customers.png"
                postURL="customers"
                postSEO
            />
            <div className='pt-200 pb-100'><CustomersComp /></div>
            <Footer />
        </Layout>
    );
}

export default Customers;

export const query = graphql`
query CustomersPageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`